.color-display {
  height: 38px;
  width: 32px;
  border: solid 1px #000;
}

.color-input {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
